/* style conflict, base css no need to use now */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

body {
	background-color: #f7f7f7 !important;
	overflow: auto;
}

/* Custom class for filling a whole boostrap block */
.img-fluid-fill {
	width: 100%;
	height: auto;
	max-width: 100%;
}


@media only screen and (max-width: 991px) {
	.grecaptcha-badge {
		visibility: hidden;
	}
}

@media screen and (max-width: 767px) {
	.hiddenBelowMD {
		display: none;
	}

	.visibleBelowMD {
		display: block;
	}
}

@media screen and (min-width: 767px) {
	.visibleBelowMD {
		display: none;
	}
}

@media screen and (max-width: 575px) {
	.hiddenBelowSM {
		display: none;
	}

	.visibleBelowSM {
		display: block;
	}
}

@media screen and (min-width: 575px) {
	.visibleBelowSM {
		display: none;
	}
}


select {
	background-color: white;
}

input {
	border-radius: 0;
}

input[type='checkbox'] {
	position: relative;
	appearance: none;
	outline: none;
	margin-right: 5px;
}

input[type='checkbox']:before {
	width: 18px;
	height: 18px;
	border: 1px solid #464a55;
	font-family: 'icomoon-jc';
	content: '\e907';
	color: transparent;
	background: #fff;
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

input[type='checkbox']:checked:before {
	font-family: 'icomoon-jc';
	content: '\e907';
	color: #464a55;
}

.prismik-link a {
	color: #444a55;
	text-decoration: none;
	cursor: pointer;
}

.prismik-link a:hover {
	color: #ee582a;
}

.Rockness {
	font-family: 'Rockness Regular';
}

.Koor {
	font-family: 'Koor Normal';
}

.location-icon::before {
	content: '\e904';
	font-family: 'icomoon-jc' !important;
	margin-right: 5px;
	font-size: 16px;
}

.bold {
	font-family: 'AvenirNextLTW01';
	font-weight: bold;
}

.section-header {
	font-size: 16px;
	text-transform: uppercase;
}

.uppercase {
	text-transform: uppercase;
}

.pointer {
	cursor: pointer;
}


.grecaptcha-badge {
	visibility: hidden;
}

/* hide trusted shop widget */
#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
	display: none;
}

@media screen and (max-width: 768px) {
	.pauseScrollContainer {
		/* position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0; */
		/* position: fixed; */
	}

	.pauseScroll {
		overflow: hidden;
		height: 100vh;
		position: relative;
	}


	.pauseScroll #whatsapp_chat_widget {
		z-index: 1;
		position: relative;
	}
}


img.blur {
	transition: opacity 0.1s, filter 0.4s 0.1s
}

img.pending {
	opacity: 0;
	filter: blur(15px);
}

img.loaded {
	opacity: 1;
	filter: blur(0px);
}

.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

@media screen and (max-width: 768px) {
	#tweakwise-guided-selling .twn-progress-bar {
		width: 330px;
		margin: 0 auto;
		border-radius: 14px;
	}

	#tweakwise-guided-selling .twn-progress-bar__fill {
		border-radius: 14px;
	}

	#tweakwise-guided-selling .twn-progress-bar__label {
		text-align: center;
		background-color: transparent;
		line-height: 14px;
		border-radius: 14px;
	}

	#tweakwise-guided-selling .twn-question__head {
		margin: 64px 0;
	}

	#tweakwise-guided-selling .twn-question__title {
		/* color: #FEF8EE; */
		text-align: center;
		font-family: Erotique;
		font-size: 32px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		width: 90%;
		margin: 0 auto;
	}

	#tweakwise-guided-selling .twn-question-list-item__input {
		display: none;
	}

	#tweakwise-guided-selling .twn-question__list {
		align-items: center;
		gap: 12px;
	}

	#tweakwise-guided-selling .twn-question-list-item {
		width: 300px;
		height: 60px;
		background: white;
		border-radius: 0;
		border: 3px solid transparent;
	}

	#tweakwise-guided-selling .twn-question-list-item.is-selected {
		position: relative;
		border: 3px solid #809A7B;
	}

	#tweakwise-guided-selling .twn-question-list-item.is-selected::after {
		content: "";
		position: absolute;
		background: url("/images/check-tweakwise.svg");
		width: 24px;
		height: 24px;
		right: 10px;
		top: 15px;
		color: #809A7B
	}

	#tweakwise-guided-selling .twn-question-list-item__head {
		height: 100%;
	}

	#tweakwise-guided-selling .twn-question-list-item__title {
		font-size: 12px;
	}

	#tweakwise-guided-selling .twn-question__foot {
		width: 232px;
		margin: 64px auto 0 auto;
	}

	#tweakwise-guided-selling .twn-question__foot .twn-button {
		width: 232px;
		height: 45px;
		border-radius: 0;
		color: black;
		font-family: "Untitled Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	#tweakwise-guided-selling .twn-question__foot .twn-button.is-primary {
		background-color: #809A7B;
	}
}


.iiz {
	display: block !important;
}
.iiz,
.iiz > div,
.iiz > div >img {
	height: 100%;
}
