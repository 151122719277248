@font-face {
    font-family: 'icomoon-jc';
    src: url('../../public/fonts/icomoon-jc.ttf') format('truetype'),
    url('../../public/fonts/icomoon-jc.woff') format('woff'),
    url('../../public/fonts/icomoon-jc.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rockness Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../public/fonts/Rockness-Vj6y.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Koor Normal';
    src: url('../../public/fonts/Koor-BoldNormal.woff2') format('woff2'),
    url('../../public/fonts/Koor-BoldNormal.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Demi.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Demi.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Regular.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Italic.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Medium-Italic.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Medium-Italic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Medium.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW02-Bold-Italic.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW02-Bold-Italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "AvenirNextLTW01";
    src: url("../../public/fonts/AvenirNextLTW01-Bold.woff2") format("woff2"),
    url("../../public/fonts/AvenirNextLTW01-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Rumpi";
    src: url("../../public/fonts/Rumpi.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}




html {
    font-size: 100%;
}

body {
    font-family: "AvenirNextLTW01", sans-serif !important;
}

h1,
h2,
h3 {
    font-family: "AvenirNextLTW01", sans-serif;
    font-weight: bold;
}

h4,
h5,
h6 {
    font-family: "AvenirNextLTW01", sans-serif;
    font-weight: normal;
}

strong {
    font-family: "AvenirNextLTW01", sans-serif;
    font-weight: bold;
}

em {
    font-family: "AvenirNextLTW01", sans-serif;
    font-style: italic;
}
