.floating{
	position:fixed;
	/*width:60px;*/
	/*height:60px;*/
	bottom:40px;
	right:40px;
	/*background-color:#25d366;*/
	color:#FFF;
	border-radius:50px;
	text-align:center;
	font-size:30px;
	box-shadow: 2px 2px 3px #999;
	z-index:100;
}
@media screen and (max-width: 648px){
	div#wa-widget-send-button {
		right: 10px!important;
		margin:0 0 10px 0!important;
	}
}
@media screen and (max-width: 400px) {
	/*Popup is too big for iPhone SE(375x667)*/
	#whatsapp_chat_widget .wa-chat-box-header{
		height: 80px;
		max-height: 80px;
		min-height: 80px;
	}
	#whatsapp_chat_widget .wa-chat-box{
		margin-bottom: 35px;
	}
}
.float-button{
	margin-top:16px;
}
